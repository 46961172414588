import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Main from "./components/MainComponent";
import { BrowserRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Main />
        </div>
        <div class="icon-bar">
          <a
            href="https://api.whatsapp.com/send?phone=918376865000"
            class=""
            role="button"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
