import "../CoreValues.css";
import star_rating from "../assets/img/star_rating.png";
import pravesh_kumar from "../assets/img/pravesh_kumar.png";
import manish_sharma from "../assets/img/manish_sharma.png";
import anmol_sharma from "../assets/img/anmol_sharma.png";

const CoreValues = () => {
  return (
    <>
      <div className="coreValues">
        <div
          class="col-12 text-center p-2 coreValuesSection"
          id="discount-block"
        >
          <h2>Core Values</h2>
          <h5 className="motto">
            <span>&#8220;</span>
            Our Motto is to serve the nation with newer age medicines with
            cutting age nutraceuticals adorned with care to all the patients.
            <span>&#8221;</span>
          </h5>
        </div>
        <ol role="list">
          <li className="integrity">
            <h3>Integrity</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Adipiscing diam donec adipiscing tristique risus.
            </p>
          </li>
          <li className="responsibility">
            <h3>Responsibility</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Adipiscing diam donec adipiscing tristique risus.
            </p>
          </li>
          <li className="unity">
            <h3>Unity</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Adipiscing diam donec adipiscing tristique risus.
            </p>
          </li>
          <li className="trust">
            <h3>Trust</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Adipiscing diam donec adipiscing tristique risus.
            </p>
          </li>
          <li className="excellence">
            <h3>Excellence</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Adipiscing diam donec adipiscing tristique risus.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
};

export default CoreValues;
