import React, { Component } from "react";
import { Link } from "react-router-dom";
import fashion from "../assets/img/dress.png";
import footwear from "../assets/img/footwear.png";
import fmcg from "../assets/img/fmcg.png";
import consumer from "../assets/img/consumer.png";
import stationery from "../assets/img/stationery.png";
import fitness from "../assets/img/fitness.png";
import globe from "../assets/img/globe.png";
import shopping from "../assets/img/shopping-cart.png";
import whatwedo from "../assets/img/whatwedo.jpg";
import whoweare from "../assets/img/whoweare.jpg";
import techsupport from "../assets/img/techsupport.jpg";
import Contact from "../assets/img/LebenswerkContact.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import AlliedServices from "../assets/img/LebenswerkAlliedServices.svg";
import Consulting from "../assets/img/LebenswerkConsulting.svg";
import EcomServices from "../assets/img/LebenswerkEcomServices.svg";
import HrServices from "../assets/img/LebenswerkHRservice.svg";
import roas from "../assets/img/roas.png";
import yoe from "../assets/img/yoe.png";
import adspends from "../assets/img/adspends.png";
import maretplace from "../assets/img/marketplace.png";
import furo from "../assets/img/furo.png";
import action from "../assets/img/action.png";
import champs from "../assets/img/champs.jpg";
import combit from "../assets/img/combit.png";
import provogue from "../assets/img/provogue.png";
import RedChief from "../assets/img/RedChief.png";
import liberty from "../assets/img/liberty.jpeg";
import bahamas from "../assets/img/bahamas.jpg";
import relaxo from "../assets/img/LebenswerkBrandrelaxo.png";
import sparx from "../assets/img/LebenswerkBrandSparx.png";
import lawman from "../assets/img/lawman.png";
import nike from "../assets/img/nike.png";
import fila from "../assets/img/fila.png";
import killer from "../assets/img/killerlogo.png";
import campus from "../assets/img/campus.jpg";
import aqualite from "../assets/img/aqualite.png";
import lotto from "../assets/img/lotto.png";
import flite from "../assets/img/flite.jpg";
import cadbury from "../assets/img/cadbury.png";
import brucoffeelogo from "../assets/img/brucoffeelogo.png";
import unilever from "../assets/img/unilever.jpg";
import pepsi from "../assets/img/pepsi.png";
import lipton from "../assets/img/lipton.png";
import tajmahal from "../assets/img/tajmahal.png";
import sugarfree from "../assets/img/sugarfree.png";
import everyouth from "../assets/img/everyouthh.jpg";
import Luxor from "../assets/img/Luxor.png";
import Parker from "../assets/img/Parker.png";
import waterman from "../assets/img/waterman.png";
import pilot from "../assets/img/pilot.png";
import vodafone from "../assets/img/vodafone.png";
import shell from "../assets/img/shell.png";
import valvoline from "../assets/img/valvoline.png";
import sale from "../assets/img/sale.png";
import cart from "../assets/img/carts.png";
import moderntrade from "../assets/img/moderntrade.png";
import largeformatretail from "../assets/img/largeformatretail.png";
import brand from "../assets/img/brand.png";
import wholesale from "../assets/img/wholesale.png";
import giftbox from "../assets/img/giftbox.png";
import domainImg from "../assets/img/domainImg.png";
import store from "../assets/img/store.png";
import dmart from "../assets/img/dmart.jpg";
import walmart from "../assets/img/walmart.png";
import bigbazaar from "../assets/img/bigbazaar.png";
import metroshoes from "../assets/img/metroshoes.png";
import metrocash2 from "../assets/img/metrocash2.png";
import itc from "../assets/img/itc.png";
import abrl from "../assets/img/abrl.svg";
import Saravana from "../assets/img/Saravana.png";
import vishal from "../assets/img/vishal.png";
import lifestyle from "../assets/img/lifestyle.png";
import styleisland from "../assets/img/styleisland.jpg";
import homeco from "../assets/img/homeco.png";
import draaxfashion from "../assets/img/draaxfashion.png";
import tatters from "../assets/img/tatters.jpg";
import devpharma from "../assets/img/devpharma.JPG";
import khwaabi from "../assets/img/khwaabi.jpg";
import metalsmith from "../assets/img/metalsmith.JPG";
import phoebee from "../assets/img/phoebee.JPG";
import gkhair from "../assets/img/gkhair.JPG";
import keune from "../assets/img/keune.JPG";
import phlogo from "../assets/img/phlogo.JPG";
import swissbeauty from "../assets/img/swissbeauty.JPG";
import tibolli from "../assets/img/tibolli.JPG";
import hilary from "../assets/img/hilary.JPG";
import bauli from "../assets/img/bauli.jpg";
import beyonist from "../assets/img/beyonist.jpg";
import mitglow from "../assets/img/mitglow.jpg";
import tushita from "../assets/img/tushita.jpg";
import SliderComp from "./SliderComp";
import Accordion from "./Accordion";
import FounderSection from "./FounderSection";
import ClientFeedback from "./ClientFeedback";
import CoreValues from "./CoreValues";

class Home extends Component {
  render() {
    const accordionData = [
      {
        title: "Are E-Commerce Solutions Important?",
        content:
          "The e-commerce industry is ever-changing and e-commerce solutions are crucial for coping with frequent advancements. That’s why online sellers get in touch with an e-commerce business solutions provider who can keep up with constant technological and industrial e-commerce market trends. ",
      },
      {
        title: "How Will You Promote My Online Business?",
        content:
          "We have an experienced team of SEO Experts who have been handling digital promotions for e-commerce businesses for quite a few years. They boost your brand name through keyword integration and sponsored ads.",
      },
      {
        title: "What E-Commerce Services Do You Provide?",
        content:
          "Lebenswerk Consulting is a trusted e-commerce business management company that provides end-to-end solutions for \n •	E-Commerce Account Management \n •	Business Consultancy \n •	HR Consultancy \n •	Allied Services \n",
      },
      {
        title: "Which E-Commerce Platforms Do You Deal With?",
        content:
          "Some of the key e-commerce platforms we deal with are categorized above.",
      },
      {
        title: "I Want To Launch My Brand On Marketplaces, Will You Help Me?",
        content:
          "Of course. We have assisted many business owners in getting their brand listed online and expanding their verticals also.",
      },
    ];
    return (
      <>
        <header class="jumbotron video-container">
          {/* <video id="myVideo" class="" autoPlay muted loop >
                <source src={Headervideo} type="video/mp4" />
              
              </video>  */}

          {/* <div class="icon-bar">
            <a
              href="https://api.whatsapp.com/send?phone=919953559179"
              class=""
              role="button"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div> */}

          <div class="container" id="bgimg">
            <div class="row row-header">
              <div class="col-12 col-sm-6 intro" id="reservebtn">
                {/* <h5>Our Experience in the Areas</h5> */}
                <div className="headerContent">
                  <h1>
                    <span>B U I L D I N G</span>&nbsp;&nbsp;
                    <br class="hide-hero1" />
                    <span>C O N S U M E R</span>&nbsp;&nbsp;
                    <br class="hide-hero" />B U S I N E S S{" "}
                  </h1>
                </div>
                <br />
                <br />

                <div class="css-typing">
                  <p>We Absorb Complexity</p>
                  <p>We Deliver Clarity.</p>
                </div>
                <br />

                <br />
                <a
                  href=""
                  data-placement="bottom"
                  class="btn btn-lg "
                  role="button"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          {/* <h6 className="text-center Aboutcomp">
            Lebenswerk Consulting is the Best E-Commerce Account Management
            Service Provider In Delhi NCR
          </h6> */}
        </header>
        <div class="container-fluid industry">
          {/* <header class="jumbotron banner-container">
            <div class="container" id="bgimg"></div>
          </header> */}
          <div class="row row-content main-intro-block">
            <div class="col-12  text-center">
              <h1 className="main-heading">
                At Maxversal Medicare, we're passionate about providing
                affordable, high-quality medicines to our customers across India
              </h1>
              {/* 
              <h5>
                At Maxversal Medicare, we're passionate about providing
                affordable, high-quality medicines to our customers across India
              </h5> */}

              <div class="industry-grid mt-3">
                <div class="grid_row service-offered">
                  <p class="mt-4">
                    Maxversal Medicare is the best Healthcare Management Service
                    Provider in India catering to the diverse needs of online
                    sellers. We serve diverse industries including Fashion,
                    Lifestyle, Footwear, Stationery, FMCG, Retail, Consumer, and
                    Internet. Our team of experts comprises highly qualified and
                    experienced people from IMS, XLRI, NIIT, FMS, and other
                    authorized institutions.
                  </p>
                </div>

                <span>
                  <Link to="/home">
                    <a
                      href="#reserve"
                      data-toggle="modal"
                      data-html="true"
                      data-target="#reserveModal"
                      data-placement="bottom"
                      class="btn btn-lg "
                      role="button"
                    >
                      Explore More
                    </a>
                  </Link>
                </span>
              </div>
            </div>
          </div>

          {/* <div class="row row-content fitplans">
            <div class="col-12 text-center p-2" id="discount-block">
              <h1 className="main-heading">
                Best E-Commerce Business Solutions Provider In India To Maximize
                Your Sales Revenue
              </h1>
              <h5>
                As more and more sellers are switching to online stores,
                generating sales has become troublesome. But if you have a
                professional e-commerce business solutions provider by your
                side, you won’t face any hassles.
              </h5>

              <div class="col-12">
                <div class="industry-grid">
                  <p class="mt-4">
                    Lebenswerk Consulting is an E-Commerce Account Management
                    Service Provider in India catering to the diverse needs of
                    online sellers. We serve diverse industries including
                    Fashion, Lifestyle, Footwear, Stationery, FMCG, Retail,
                    Consumer, and Internet. Our team of experts comprises highly
                    qualified and experienced people from IMS, XLRI, NIIT, FMS,
                    and other authorized institutions.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">What We Do </h3>

              <p class="mt-4">
                Business Transformation | e-Commerce Management | Sales
                Structure Planning | Corporate Planning | Annual Business
                Planning | Supply Chain Set-up | Processes Implementation |
                Training &amp; Development | Employees KRA &amp; KPI Formulation
                | GTM Planning | Financial Assessment | Performance Marketing
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                Channel Sales
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                LFR
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                E-commerce
              </a>
            </div>

            <div class="col-sm-6 p-2">
              <div class="row">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkRetail} class="img-fluid" />
                </div>
              </div>
            </div>
            <br />

            <div class="col-sm-6 p-2">
              <div class="row d-none d-sm-block">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkFmcg} class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Who We Are </h3>

              <p class="mt-4">
                Lebenswerk's core team has vast experience into various consumer
                domains industries and worked across top-notch MNC &amp; Leading
                consumer companies in India. Academics background of our team
                are from XLRI - IMS - NIIT - FMS-ISS – Other Top Colleges,
                highly analytical and process oriented.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                XLRI
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                IMS
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                NIIT
              </a>
            </div>

            <div class="row  d-block d-sm-none">
              <div class="col p-5">
                <img src={LebenswerkFmcg} class="img-fluid" />
              </div>
            </div>
          </div> */}

          <div class="row row-content fitplans about-us-section">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>About Us</h2>
              <h5 className="motto">
                <span>&#8220;</span>
                Our Motto is to serve the nation with newer age medicines with
                cutting age nutraceuticals adorned with care to all the
                patients.
                <span>&#8221;</span>
              </h5>
              {/* <h4>
                <span>&#8220;</span>
                Our Motto is to serve the nation with newer age medicines with
                cutting age nutraceuticals adorned with care to all the
                patients.
                <span>&#8221;</span>
              </h4> */}
            </div>

            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Mission </h3>

              <p class="mt-4">
                To build the people, process and quality focussed healthcare
                company.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                Channel Sales
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                LFR
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                E-commerce
              </a>
            </div>

            <div class="col-sm-6 p-2">
              <div class="row">
                <div class="col p-5 mission">
                  <img src={whatwedo} class="img-fluid" />
                </div>
              </div>
            </div>
            <br />

            <div class="col-sm-6 p-2">
              <div class="row d-none d-sm-block">
                <div class="col p-3 vision">
                  {" "}
                  <img src={whoweare} class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Vision </h3>

              <p class="mt-4">
                To be recognised as one of the topmost integrated healthcare
                provider to all patients.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                XLRI
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                IMS
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                NIIT
              </a>
            </div>

            <div class="row  d-block d-sm-none">
              <div class="col p-5">
                {" "}
                <img src={whoweare} class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="row row-content services services-offered-section">
            <div class="col-12  text-center" id="discount-block">
              <h2 class="mt-3">Areas of Interest</h2>
              <h5>Maxversal Medicare Core Services</h5>
              <div class="industry-grid mt-3">
                <div class="grid_row service-offered">
                  <div class="grid_item hospitals">
                    {/* <div class="grid_img">
                      <img src={EcomServices} class="img-fluid" />
                    </div> */}
                    {/* <span class="grid_text">Orthopaedics Care</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Orthopaedics Care</span>
                    </p>
                    <Link to="/ecommerce-account-management-services">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">E-Commerce Services </p>
                          <p>
                            As a professional e-commerce business solutions
                            provider in India Lebenswerk Consulting offer
                            full-stack e-commerce services across varied
                            industries. We assist businesses to market their
                            products/services and manage orders, inventory,
                            supply chain, payments, and listings. With
                            360-degree e-commerce solutions, we thoroughly
                            undertake analytical research for website
                            optimization and implement measures that positively
                            foster your brand image and generate more sales.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  <div class="grid_item pharma">
                    {/* <div class="grid_img">
                      <img src={HrServices} class="img-fluid" />
                    </div> */}
                    {/* <span class="grid_text">Gynaecology Care</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Gynaecology Care</span>
                    </p>
                    <Link to="/hr-consultancy-services">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">HR Consultancy Services </p>
                          <p>
                            We provide qualified people for your e-commerce
                            business management. Our strategic approach involves
                            an in-depth analysis of profile requirements and
                            accordingly placing the right people in the right
                            positions. We ascertain individual and collective
                            KPIs for employees and help you in decision-making
                            regarding how to invest in human resources. Our
                            employee performance analysis will save you both
                            time and money.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  <div class="grid_item retail_med">
                    {/* <div class="grid_img">
                      <img src={Consulting} class="img-fluid" />
                    </div> */}
                    {/* <span class="grid_text">Neurology care</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Neurology care</span>
                    </p>
                    <Link to="/ecommerce-business-management-company">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Business Consulting </p>
                          <p>
                            Managing business operations in the e-commerce world
                            is not that easy. That’s why organizations get
                            confused about deciding where to start and where to
                            end. Our experienced team knows the ins and outs in
                            this regard very well. We formulate several SOPs and
                            design the best methodologies as per your business
                            to analyze different areas related to online
                            marketing, employee maintenance, products/services
                            portfolio, financial allocation, supply chain, and
                            logistics smoothly.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  <div class="grid_item nutra">
                    {/* <div class="grid_img">
                      <img src={Consulting} class="img-fluid" />
                    </div>
                    <span class="grid_text">Nutraceuticals</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Nutraceuticals</span>
                    </p>
                    <Link to="/ecommerce-business-management-company">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Business Consulting </p>
                          <p>
                            Managing business operations in the e-commerce world
                            is not that easy. That’s why organizations get
                            confused about deciding where to start and where to
                            end. Our experienced team knows the ins and outs in
                            this regard very well. We formulate several SOPs and
                            design the best methodologies as per your business
                            to analyze different areas related to online
                            marketing, employee maintenance, products/services
                            portfolio, financial allocation, supply chain, and
                            logistics smoothly.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  {/* <div class="grid_item">
                    <div class="grid_img">
                      <img src={AlliedServices} class="img-fluid" />
                    </div>
                    <span class="grid_text">Allied Services</span>
                    <p className="services-keywords">
                      Initiating New Business Avenues
                      <br />
                      Channel Partners Appointment
                      <br />
                      Marketing Services
                    </p>

                    <Link to="/mid-level-management-recruitment-consultants">
                      <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Allied Services</p>
                          <p>
                            E-commerce store owners require such an e-commerce
                            business solutions provider who can diligently
                            decide on a channel partner for your collaboration.
                            We are a team of experienced expert professionals
                            who are highly skilled to figure out such a heavy
                            requirement. Our E-Commerce SEO team will also
                            foster your brand listings and highlight them in
                            front of your target audience. With seo-optimized
                            content and site, we assure you quality traffic and
                            an increase in revenue.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div class="row row-content clientNum stats">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>What Makes Us Stand Out</h2>
              <h5>Maxversal Medicare Transforms Your Business</h5>
            </div>
            <div class="projectnumber wow fadeInUp" data-wow-duration=".5s">
              <div class="main-total-box anim-counter">
                <div class="client-number">
                  <img src={roas} class="img-fluid grid_img" />
                  <strong class="newde">Innovative launch targeting </strong>
                  {/* <span data-content="home.stat.1">
                    Innovative launch targeting
                  </span> */}
                </div>
                <div class="client-number">
                  <img src={maretplace} class="img-fluid grid_img" />
                  <strong class="newde">Touched 10 Million Lives</strong>
                  {/* <span data-content="home.stat.1">
                    SKUs Listed on various Marketplaces
                  </span> */}
                </div>
                {/* <div class="client-number">
                  <img src={adspends} class="img-fluid grid_img" />
                  <strong>30 Mn</strong>
                  <span data-content="home.stat.2">
                    Ads Spends managed Efficiently
                  </span>
                </div> */}
                <div class="client-number">
                  <img src={yoe} class="img-fluid grid_img" />
                  <strong>(2+) Years of Service</strong>
                  {/* <span data-content="home.stat.3">
                    Years of Service Excellence
                  </span> */}
                </div>
              </div>
            </div>
          </div>

          <FounderSection />

          <CoreValues />

          <div class="row row-content contact">
            <div
              class="col-12 text-center px-5 align-items-center"
              id="whatsapp-block"
            >
              <h2>Feel Free to Contact Us 24x7 → &nbsp;</h2>
              {/*      
    <a href="mailto:info@lebenswerk.in"> <i class="fas fa-envelope"></i>
                      */}
              <a
                href="https://api.whatsapp.com/send?phone=918376865000"
                class="btn btn-lg whatspp-head"
                role="button"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="whatspp-head" />
              </a>
              <a href="tel:08376865000" class="btn btn-lg " role="button">
                <FontAwesomeIcon icon={faPhone} />
              </a>
              &nbsp;
              <img src={Contact} class="img-fluid" />
            </div>
          </div>
          <ClientFeedback />
        </div>
      </>
    );
  }
}

export default Home;
